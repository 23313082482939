<template>
  <md-content>
    <h4 v-if="songs.tracks.items !== undefined && songs.tracks.items.length > 0">Search results</h4>
    <div class="" v-for="(s, key) in songs.tracks.items" :index="key" :key="key">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <aplayer
              :autoplay="false"
              v-if="songs.tracks.items[key].preview_url !== ''"
              :mini="true"
              :music="{
                        title: songs.tracks.items[key].name,
                        artist: songs.tracks.items[key].artists[0].name,
                        src: songs.tracks.items[key].preview_url,
                        pic: songs.tracks.items[key].album.images[2].url,
                      }"
          />
          <div v-else-if="songs.tracks.items[key].album.images[2].url !== ''">
            <img alt="album" v-bind:src="songs.tracks.items[key].album.images[2].url"/>
          </div>
          <div v-else>No Preview</div>
        </div>
        <div class="md-layout-item">
          <h4>{{ songs.tracks.items[key].name }}</h4>
          <h6>
            {{ songs.tracks.items[key].artists[0].name }}
          </h6>
        </div>
        <div class="md-layout-item text-right">
          <md-button :disabled=isButtonDisabled class="md-just-icon md-error"
                     @click="addSong(songs.tracks.items[key].id, songs.tracks.items[key].name, songs.tracks.items[key].artists[0].name)">
            <md-icon title="Add song to playlist">add</md-icon>
          </md-button>
          <!--          <md-button v-if="live_queue" class="md-just-icon md-rose"-->
          <!--                     @click="queueSong(songs.tracks.items[key].id)">-->
          <!--            <md-icon title="Add song to the queue">queue_music</md-icon>-->
          <!--          </md-button>-->
        </div>
      </div>
    </div>
  </md-content>
</template>

<script>
import Aplayer from "vue-aplayer";
import {addSongToSpotify} from "../../services/spotify";

export default {
  name: "SearchSongResults",
  data() {
    return {
      live_queue: false,
      isButtonDisabled: false
    };
  },
  components: {
    Aplayer,
  },
  props: {
    songs: Object,
    displayResult: Boolean
  },
  methods: {
    async addSong(songId, songName, songArtist) {

      this.isButtonDisabled = true
      try {
        await addSongToSpotify(this.$route.params.user_id, this.$route.params.playlist_id, songId, songName, songArtist)
        this.$parent.getPlaylistSongs(this.$route.params.playlist_id)
        this.isButtonDisabled = false
      } catch (e) {
        this.isButtonDisabled = false
        this.$alert(e.response.data, "Error", "error");
      }
    }
  }
}
</script>

<style scoped>

</style>
