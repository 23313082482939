import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCookies from "vue-cookies";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VueQRCodeComponent from "vue-qr-generator";
import VueClipboard from "vue-clipboard2";
import VueSimpleAlert from "vue-simple-alert";
import VueAPlayer from "vue-aplayer";
import MaterialKit from "./plugins/material-kit";

Vue.config.productionTip = false;
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(MaterialKit);
Vue.use(VueCookies);
Vue.use(VueMaterial);
Vue.use(VueAPlayer);
Vue.use(VueClipboard);
Vue.use(VueSimpleAlert);
const NavbarStore = {
  showNavbar: false,
};

Vue.mixin({
  data() {
    return {
      NavbarStore,
    };
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
