<template>
<div>
  Coming soon...
</div>
</template>

<script>
import {browseCategories} from "../../services/spotify";

export default {
  name: "BrowseCategories",
  mounted() {
    this.browseCategories()
  },
  methods: {
    async browseCategories() {
      const categories = await browseCategories(this.$route.params.user_id)
      console.log(categories, 'categories');
    }
  }
}
</script>

<style scoped>

</style>
