<template style="text-align: left">
  <div>
    <div class="md-layout" style="text-align: left; margin-bottom: 10px">
      <div class="md-layout-item">
        <md-field class="">
          <md-input
              v-model="playlistName"
              placeholder="New playlist name"
          ></md-input>
          <md-button
              class="md-primary"
              :disabled="sending"
              @click="createPlaylist"
          >
            <md-icon title="Create New Playlist">add</md-icon>
          </md-button>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <md-input
              placeholder="Description"
              v-model="description"
              type="text"
          ></md-input>
        </md-field>
      </div>
    </div>
    <div class="title">
      <h3>Enable Live Queue on Playlist</h3>
    </div>
    <div class="flex-column">
      <md-switch v-model="switch1"
      >Live Queue is {{ this.switch1 ? "on" : "off" }}
      </md-switch
      >
    </div>
    <div class="md-layout" style="text-align: left">
      <div class="md-layout-item" v-if="this.playlists.length > 0">
        <md-table>
          <md-table-row
              v-for="(list, key) in playlists"
              :index="key"
              :key="key"
          >
            <md-table-cell>
              {{ playlists[key].name }}
            </md-table-cell>
            <md-table-cell>
              {{ playlists[key].total_songs }}
            </md-table-cell>
            <md-table-cell>
              <router-link
                  :to="{
                  path:
                    '/p/' + user.uid + '/' + playlists[key].id,
                }"
                  class="nav-link"
              >
                Songs
              </router-link>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebaseInit";

import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import {isPlaylistAdmin} from "../services/auth";

export default {
  name: "UserPage",

  mixins: [validationMixin],
  props: {
    msg: String,
  },
  validations: {
    form: {
      playlistName: {
        required,
        minLength: minLength(3),
      },
    },
  },
  created: function () {
    if (isPlaylistAdmin()) {
      this.user = firebase.auth().currentUser;
      this.getUserPlaylists(this.user.uid);
    } else {
      this.$router.replace({
        name: "Home"
      });
    }
  },
  data() {
    return {
      user: {
        uid: ""
      },
      playlists: [],
      menuVisible: false,
      switch1: false,
      showDialog: false,
      sending: false,
      playlistName: null,
      description: null,
      isValidPlaylistName: true,
      songs: {
        tracks: {
          items: [],
        },
      },
    };
  },
  methods: {
    getValidationClass() {
      return {
        "md-invalid": true,
      };
    },
    getUserPlaylists(userId) {
      if (userId === null) {
        this.$alert("User not found", "", "warning");
        this.$router.replace({
          name: "Home"
        });
      } else {
        firebase
            .functions(process.env.VUE_APP_API_ENDPOINT)
            .httpsCallable("api/v1/user-get-playlists?user_id=" + userId)()
            .then((r) => {
              if( r.data !== null) {
                this.playlists = r.data;
              }
            })
            .catch((e) => {
              this.$alert(e, "Error", "error");
            });
      }
    },
    createPlaylist(e) {
      e.preventDefault();
      if (this.playlistName === null) {
        this.isValidPlaylistName = false;
        this.getValidationClass();
        this.$alert("A playlist name is required", "Playlist Error", "warning");
      } else {
        this.isValidPlaylistName = true;
        this.sending = true;
        firebase
            .functions(process.env.VUE_APP_API_ENDPOINT)
            .httpsCallable("api/v1/create-playlists")({
              name: this.playlistName,
              description: this.description,
              user_id: this.user.uid,
              live_queue: this.switch1,
            })
            .then((r) => {
              console.log(this.user)
              this.getUserPlaylists(this.user.uid);
              this.sending = false;
              this.playlistName = "";
              this.description = "";
              this.$router.replace({
                name: "Playlist",
                params: {
                  user_id: this.user.uid,
                  playlist_id: r.data.id,
                }
              });
            })
            .catch((e) => {
              this.sending = false;

              this.$alert(e, "Error", "error");
            });
      }
    },
  },
};
</script>
