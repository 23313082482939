<template>
  <div class="wrapper" v-if="song !== undefined">
    <h4>Now playing</h4>
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <aplayer
            :autoplay="false"
            v-if="song.item.preview_url !== ''"
            :mini="true"
            :music="{
              title: song.item.name,
              artist: song.item.artists[0].name,
              src: song.item.preview_url,
              pic: song.item.album.images[2].url,
            }"
        />
        <div v-else-if="song.item.album.images[2].url !== ''">
          <img alt="album" v-bind:src="song.item.album.images[2].url"/>
        </div>
        <div v-else>No Preview</div>
      </div>
      <div class="md-layout-item">
        <h4>{{ song.item.name }}</h4>
        <h6>
          {{ song.item.artists[0].name }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import Aplayer from "vue-aplayer";

export default {
  name: "NowPlaying",
  props: {
    song: Object
  },
  components: {
    Aplayer,
  },
}
</script>

<style scoped>

</style>
