<template>
  <div class="wrapper">
    <parallax class="page-header header-filter">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper"></div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <h3>Sounds Rare</h3>
          <h4>
            Sounds Rare NFT is a limited 1,000 membership-tier group with exclusive access to song ownership, unreleased
            music, musicians, and collectible generative art drops. Benefits are continual for members. Only 20 of every
            character will ever be minted, each one with different generative attributes such as color way, vinyl record
            style, and sticker packaging.
          </h4>
          <h4>
            <a href="https://www.soundsrarenft.io" target="_blank">https://www.soundsrarenft.io</a>
          </h4>
          <h4>
            Here is a list of the latest airdropped songs. Songs might take some time to load since the data is coming
            from AWS and not a CDN.
          </h4>
          <div class="" v-for="(s, key) in tracks" :index="key" :key="key">
            <aplayer
                :autoplay="false"
                :music="{
                        title:  tracks[key].name,
                        artist: 'SoundsRare',
                        src: tracks[key].track,
                        pic: tracks[key].image,
                      }"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aplayer from "vue-aplayer";

export default {
  name: "SoundsRare",
  data() {
    return {
      tracks: require("@/assets/soundsRareMusic.json"),
    };
  },
  components: {
    Aplayer,
  },
}
</script>

<style scoped>

</style>
