<template>
  <div v-if="this.songs.length > 0">
    <div class="md-layout md-gutter">
      <div class="md-layout-item scrollmenu">
        <div style="min-height: 30px">
          <a href="#" v-for="(v, key) in this.songs" :index="key" :key="key"  @click="addSong(v.songID, v.name, v.artist)">
            <b>{{ v.name }}</b>
            <span>
               by {{ v.artist }},
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import idb from "../../services/database";
import {addSongToSpotify} from "../../services/spotify";

export default {
  name: "PreviouslyAdded",
  mounted: function () {
    this.getPreviouslyAdded();
  },
  data() {
    return {
      songs: []
    }
  },
  methods: {
    async addSong(songId, songName, songArtist) {
      await addSongToSpotify(this.$route.params.user_id, this.$route.params.playlist_id, songId,  songName, songArtist)
      this.$root.$emit('refreshPlaylist')
      console.log('s')
    },
    async getPreviouslyAdded() {
      const allSongs = await idb.getSongs()
      const songs = allSongs.reduce((acc, current) => {
        const x = acc.find(item => item.songID === current.songID);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
      this.songs = songs
    }
  }
}
</script>

<style scoped>
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
</style>
