<template style="text-align: left">
  <div>
    <div class="md-layout" style="text-align: left; margin-bottom: 10px">
      <div class="md-layout-item">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-xsmall-size-50 md-size-15">
            <qr-code :text="this.endpoint" :size="100"></qr-code>
          </div>
          <div class="md-layout-item text-left">
            <div class="md-title">{{ name }}</div>
            <div class="md-subheading">{{ description }}</div>
            <div class="md-subheading">{{ totalSongs }}</div>
            <div class="md-subheading">{{ totalTime }}</div>
            <md-button class="md-icon-button md-raised md-accent md-just-icon md-rose">
              <md-icon v-clipboard:copy="this.endpoint" v-clipboard:success="onCopySuccess"
                       v-clipboard:error="onCopyError">share
              </md-icon>
            </md-button>
          </div>
        </div>
      </div>
    </div>
    <SearchSong :playlistId=this.$route.params.playlist_id></SearchSong>
    <TabsSection></TabsSection>
    <NowPlaying :song=currentSongPlaying></NowPlaying>
    <PlaylistSongs :songs=songs></PlaylistSongs>
  </div>
</template>

<script>
import firebase from "../firebaseInit";
import NowPlaying from "./Playlist/NowPlaying";
import SearchSong from "./Playlist/SearchSong";
import PlaylistSongs from "./Playlist/PlaylistSongs";
import TabsSection from "./Playlist/TabsSection";

const formatDuration = ms => {
  if (ms < 0) ms = -ms;
  const time = {
    day: Math.floor(ms / 86400000),
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60
  };
  return Object.entries(time)
      .filter(val => val[1] !== 0)
      .map(val => val[1] + ' ' + (val[1] !== 1 ? val[0] + 's' : val[0]))
      .join(', ');
};

export default {
  name: "PlaylistPage",
  components: {
    TabsSection,
    PlaylistSongs,
    SearchSong,
    NowPlaying,
  },
  mounted: function () {
    this.init();
    this.$root.$on('refreshPlaylist', () => {
      this.getPlaylistSongs(this.$route.params.playlist_id);
    })
  },
  created() {
    if (firebase.auth().currentUser === null) {
      firebase.auth().signInAnonymously()
    }
  },
  data() {
    return {
      endpoint: "https://partyrino.com" + this.$route.fullPath,
      name: "Loading...",
      description: "",
      totalSongs: "",
      totalTime: "",
      currentSongPlaying: undefined,
      live_queue: false,
      songs: {},
    };
  },
  methods: {
    init() {
      this.getPlaylistSongs(this.$route.params.playlist_id);
      this.currentlyPlaying();
    },
    currentlyPlaying() {
      firebase
          .functions(process.env.VUE_APP_API_ENDPOINT)
          .httpsCallable("api/v1/currently-playing")({
            playlist_id: this.$route.params.playlist_id,
            user_id: this.$route.params.user_id,
          })
          .then((r) => {
            if (r.data.is_playing) {
              this.currentSongPlaying = r.data
            }
          })
          .catch((e) => {
            console.log(e)
          });
    },
    onCopySuccess() {
      this.$alert("URL has been copied!", "Success", "success");
    },
    onCopyError() {
      this.$alert("Could not copy URL", "Error", "error");
    },

    getPlaylistSongs(playlistId) {
      firebase
          .functions(process.env.VUE_APP_API_ENDPOINT)
          .httpsCallable("api/v1/playlist-songs")({
            playlist_id: playlistId,
            user_id: this.$route.params.user_id,
          })
          .then((r) => {
            if (r.data.songs !== null) {
              this.songs = r.data.songs.sort((a, b) => {
                return new Date(b.added_at) - new Date(a.added_at);
              });
            } else {
              this.songs = []
            }
            this.name = r.data.name;
            this.description = r.data.description;
            this.live_queue = r.data.live_queue;
            const info = this.getSongInfo(r.data.songs);
            this.totalSongs = info.totalSongs
            this.totalTime = info.totalTime
          })
          .catch((e) => {
            console.log(e)
          });
    },
    getSongInfo(tracks) {
      let totalPlayTime = 0
      let songs = "songs";
      let totalSongs = 0;
      if (tracks !== null) {
        totalSongs = tracks.length
        tracks.forEach(function (track) {
          totalPlayTime = track.track.duration_ms + totalPlayTime
        });
        if (tracks.length === 1) {
          songs = "song";
        }
      }

      const playTime = formatDuration(totalPlayTime)

      return {
        totalSongs: totalSongs + " " + songs,
        totalTime: playTime,
      }
    },
  },
};
</script>
