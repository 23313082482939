<template>
    <div style="">
        <h1>Create, add and share music to your Spotify playlist.</h1>
        <h4>(without having your friends mess with the queue)</h4>
        <div>
            <a href="#" @click="login"> Log in with Spotify </a>
        </div>
        <p>
            You can create custom playlists, and share an URL where your friends and family can add or remove songs to
            the queue without interrupting the current music playing.
        </p>
    </div>
</template>

<script>
import firebase from "../firebaseInit";

export default {
    name: "HomePage",
    props: {
        msg: String,
    },

    methods: {
        login() {
            firebase
                .auth()
                .signInAnonymously()
                .then(() => {
                    firebase
                        .functions(process.env.VUE_APP_API_ENDPOINT)
                        .httpsCallable("api/v1/login")()
                        .then((response) => {
                            window.location.href = response.data;
                        })
                        .catch((e) => {
                            this.$alert(e, "Error", "error");
                        });
                })
                .catch((e) => {
                    this.$alert(e, "Error", "error");
                });
        },
    },
};
</script>
