import firebase from "../firebaseInit";

export function isPlaylistAdmin() {
  const user = firebase.auth().currentUser;
  if (user === null) {
    return false;
  }
  return (
    user.uid !== null &&
    user.isAnonymous !== undefined &&
    user.isAnonymous === false
  );
}
