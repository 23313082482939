<template>
  <div class="md-layout" style="text-align: left" ref="playlist-songs" >
    <div class="md-layout-item">
      <h4>Playlist songs</h4>
      <div class="md-layout">
        <div class="md-layout-item  md-xsmall-size-100 md-size-50" v-for="(list, key) in songs" :index="key" :key="key">
          <div class="md-layout md-gutter" v-if="songs[key].track.preview_url !== ''">
            <div
                class="md-layout-item  md-xsmall-size-35 md-small-size-35  md-medium-size-25 md-large-size-20 md-xlarge-size-20">
              <aplayer
                  :autoplay="false"
                  :mini="true"
                  :music="{
                    title: songs[key].track.name,
                    artist: songs[key].track.artists[0].name,
                    src: songs[key].track.preview_url,
                    pic: songs[key].track.album.images[2].url,
                  }"
              />
            </div>
            <div class="md-layout-item md-xlarge-size-33">
              <h4>{{ songs[key].track.name }}</h4>
              <h6>{{ songs[key].track.artists[0].name }}</h6>
              <h6 v-if="songs[key].added_by.display_name !== ''"> Added by {{ songs[key].added_by.display_name }}</h6>
            </div>
            <div class="md-layout-item  text-right  md-xsmall-size-100 md-xlarge-size-33">
              <md-button class="md-just-icon md-danger md-just-icon" @click="removeSong(songs[key].track.id)">
                <md-icon title="Delete song from playlist">delete</md-icon>
              </md-button>
              <md-button v-if="live_queue" class="md-just-icon md-rose md-just-icon" @click="queueSong(songs[key].track.id)">
                <md-icon title="Add song to the queue">queue_music</md-icon>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aplayer from "vue-aplayer";
import firebase from "../../firebaseInit";

export default {
  name: "PlaylistSongs",
  components: {
    Aplayer,
  },
  props: {
    songs: []
  },
  data() {
    return {
      endpoint: "https://partyrino.com" + this.$route.fullPath,
      name: "Loading...",
      description: "",
      info: "",
      show_results: false,
      live_queue: false,
      show_loading: false,
      show_dialog: false,
      show_qr_code: false,
      query: null,
      query_results: {
        tracks: {
          items: [],
        },
      },
    };
  },

  methods: {
    removeSong(songId) {
      firebase
          .functions(process.env.VUE_APP_API_ENDPOINT)
          .httpsCallable("api/v1/remove-song")({
            playlist_id: this.$route.params.playlist_id,
            song_id: songId,
            user_id: this.$route.params.user_id,
          })
          .then(() => {
            this.$parent.getPlaylistSongs(this.$route.params.playlist_id)
          })
          .catch((e) => {
            this.$alert(e.response.data, "Error", "error");
          });
    },
  },
}
</script>

<style scoped>

</style>
