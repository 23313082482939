<template>
  <div class="wrapper">
    <parallax class="page-header header-filter">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper"></div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised text-center">
      <div class="section">
        <div class="container">
          <CallbackPage />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CallbackPage from "@/components/CallbackPage";

export default {
  name: "Callback",
  components: {
    CallbackPage,
  },
};
</script>
