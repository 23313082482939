import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Callback from "../views/Callback.vue";
import User from "../views/User.vue";
import Playlist from "../views/Playlist.vue";
import SoundsRare from "../views/SoundsRare.vue";
import MainFooter from "../layout/MainFooter.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
      footer: MainFooter,
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "black" },
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "black" },
    },
  },
  {
    path: "/callback",
    name: "Callback",
    components: {
      default: Callback,
      footer: MainFooter,
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "black" },
    },
  },
  {
    path: "/user",
    name: "User",
    components: {
      default: User,
      footer: MainFooter,
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "black" },
    },
  },
  {
    path: "/p/:user_id/:playlist_id",
    name: "Playlist",
    components: {
      default: Playlist,
      footer: MainFooter,
    },
    props: {
      header: { colorOnScroll: 10 },
      footer: { backgroundColor: "black" },
    },
  },
  {
    path: "/soundsrare",
    name: "SoundsRare",
    components: {
      default: SoundsRare,
      footer: MainFooter,
    },
    props: {
      header: { colorOnScroll: 10 },
      footer: { backgroundColor: "black" },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
