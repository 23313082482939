import firebase from "../firebaseInit";
import idb from "./database";
import getEmoji from "get-random-emoji";
import {
  adjectives,
  animals,
  uniqueNamesGenerator,
} from "unique-names-generator";

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

function createCookie(name, value, days) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

export async function addSongToSpotify(
  userId,
  playlistId,
  songId,
  songName,
  songArtist
) {
  let addedBy = getCookie("anon_name");
  if (addedBy === "") {
    addedBy =
      uniqueNamesGenerator({
        dictionaries: [adjectives, animals],
        length: 2,
        style: "capital",
        separator: " ",
      }) +
      " " +
      getEmoji();
    createCookie("anon_name", addedBy, 10000);
  }

  return firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/add-song")({
      playlist_id: playlistId,
      song_id: songId,
      user_id: userId,
      added_by: addedBy,
    })
    .then(() => {
      idb.saveSong(songId, playlistId, userId, songName, songArtist);
    });
}

export async function recommendations(userId, playlistId) {
  const data = await firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/recommendations")({
    playlist_id: playlistId,
    user_id: userId,
  });
  return data.data.tracks;
}

export async function play(userId) {
  const data = await firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/play")({
    user_id: userId,
  });
  return data.data;
}

export async function pause(userId) {

  const data = await firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/pause")({
    user_id: userId,
  });
  return data.data;
}

export async function skipNext(userId) {
  const data = await firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/skip-next")({
    user_id: userId,
  });
  return data.data;
}

export async function skipPrevious(userId) {
  const data = await firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/skip-previous")({
    user_id: userId,
  });
  return data.data;
}

export async function browseCategories(userId) {
  const data = await firebase
    .functions(process.env.VUE_APP_API_ENDPOINT)
    .httpsCallable("api/v1/browse-categories")({
    user_id: userId,
  });
  return data.data;
}
