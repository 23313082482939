<template>
  <div>
    <h2>Congratulations you logged in with Spotify 🥳</h2>
    <img src="@/assets/img/party_rino.png" alt="Party Rino" />
  </div>
</template>

<script>
import firebase from "./../firebaseInit";

if (process.env.NODE_ENV === "development") {
  firebase.functions().useEmulator("localhost", 5001);
}

export default {
  name: "CallbackPage",
  props: {
    msg: String,
  },
  mounted: function () {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        firebase
          .functions(process.env.VUE_APP_API_ENDPOINT)
          .httpsCallable("api/v1/authenticate")({
            state: this.$route.query.state,
            code: this.$route.query.code,
          })
          .then((r) => {
            firebase.auth().signInWithCustomToken(r.data).then(() => {
              this.$router.replace({
                name: "User"
              });
            }).catch(e => {
              this.$alert(e, "Error", "error");
            })
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e, "Error", "error");
          });
      })
      .catch((e) => {
        this.$alert(e, "Error", "error");
      });
  },
};
</script>
