<template>
  <div class="wrapper">
    <div v-if="isAdmin">
      <md-toolbar
          id="toolbar"
          md-elevation="0"
          class="md-white md-absolute"
      >
        <div class="md-toolbar-row md-collapse-lateral">
          <div class="md-toolbar-section-start" v-if="this.$route.params.playlist_id !== undefined">
            <md-button class="md-just-icon md-simple" @click="home">
              <md-icon>home</md-icon>
            </md-button>
            <md-button class="md-just-icon md-simple" @click="skipPrevious">
              <md-icon>skip_previous</md-icon>
            </md-button>
            <md-button class="md-just-icon md-simple" @click="play">
              <md-icon>play_circle_outline</md-icon>
            </md-button>
            <md-button class="md-just-icon md-simple" @click="pause">
              <md-icon>pause</md-icon>
            </md-button>
            <md-button class="md-just-icon md-simple" @click="skipNext">
              <md-icon>skip_next</md-icon>
            </md-button>
          </div>
        </div>
      </md-toolbar>
    </div>
    <parallax class="page-header header-filter">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper"></div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <PlaylistPage />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlaylistPage from "../components/PlaylistPage";
import {pause, play, skipNext, skipPrevious} from "../services/spotify";
import {isPlaylistAdmin} from "../services/auth";

export default {
  name: "Playlist",
  components: {
    PlaylistPage,
  },
  data() {
    return {
      isAdmin: false
    };
  },
  methods: {
    home() {
      this.$router.replace({
        name: "User"
      });
    },
    pause() {
      pause(this.$route.params.user_id)
    },
    play() {
      play(this.$route.params.user_id)
    },
    skipNext() {
      skipNext(this.$route.params.user_id)
    },
    skipPrevious() {
      skipPrevious(this.$route.params.user_id)
    },
  },
  created() {
    this.isAdmin = isPlaylistAdmin()
  }
};
</script>
