<template>
  <div class="wrapper">
    <parallax class="page-header header-filter">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper"></div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <UserPage />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserPage from "../components/UserPage";

export default {
  name: "User",
  components: {
    UserPage,
  },
};
</script>
