const DB_NAME = "partyrino";
const ADDED_SONGS_TABLE = "added_songs";
const DB_VERSION = 1;
let DB;

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        console.log("Error opening db", e);
        reject("Error");
      };

      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onupgradeneeded = (e) => {
        let db = e.target.result;
        db.createObjectStore(ADDED_SONGS_TABLE, {
          autoIncrement: true,
          keyPath: "id",
        });
      };
    });
  },
  async deleteSong(song) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction([ADDED_SONGS_TABLE], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(ADDED_SONGS_TABLE);
      store.delete(song.id);
    });
  },

  async getSongs() {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction([ADDED_SONGS_TABLE], "readonly");
      trans.oncomplete = () => {
        resolve(songs.sort((a, b) => (a.id < b.id ? 1 : -1)));
      };

      let store = trans.objectStore(ADDED_SONGS_TABLE);
      let songs = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          songs.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async saveSong(songID, playlistID, userID, name, artist) {
    const song = {
      songID: songID,
      playlistID: playlistID,
      userID: userID,
      name: name,
      artist: artist,
    };
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction([ADDED_SONGS_TABLE], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(ADDED_SONGS_TABLE);
      store.put(song);
    });
  },
};
