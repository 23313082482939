<template>
  <div v-if="this.songs.length > 0">
    <div class="md-layout md-gutter" v-if="this.songs.length > 0">
      <div class="md-layout-item scrollmenu">
        <div style="min-height: 30px">
          <a href="#" v-for="(v, key) in this.songs" :index="key" :key="key" @click="addSong(v.id, v.name, v.artists[0].name)">
            <b>{{ v.name }}</b>
            <span>
               by {{ v.artists[0].name }},
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../firebaseInit";
import {addSongToSpotify} from "../../services/spotify";

export default {
  name: "TopUserTracks",
  mounted: function () {
    this.getTopUserTracks()
  },
  data() {
    return {
      songs: []
    }
  },
  methods: {
    async addSong(songId, songName, songArtist) {
      await addSongToSpotify(this.$route.params.user_id, this.$route.params.playlist_id, songId, songName, songArtist)
      this.$root.$emit('refreshPlaylist')
    },
    getTopUserTracks() {
      firebase
          .functions(process.env.VUE_APP_API_ENDPOINT)
          .httpsCallable("api/v1/top-user-tracks")({
            user_id: this.$route.params.user_id,
          })
          .then((r) => {
            this.songs = r.data.items
          })
          .catch((e) => {
            console.log(e)
            //this.$alert(e, "Error", "error");
          });
    },
  }
}
</script>

<style scoped>
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
</style>
