<template>
  <div class="wrapper">
    <div id="nav-tabs">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <nav-tabs-card no-label>
            <template slot="content">
              <md-tabs class="md-primary" md-alignment="left">
                <md-tab id="tab-top-songs" md-label="Top Songs"  md-icon="star">
                  <TopUserTracks></TopUserTracks>
                </md-tab>
                <md-tab id="tab-recommendations" md-label="Recommended Songs" md-icon="smart_toy">
                  <Recommendations></Recommendations>
                </md-tab>
                <md-tab id="tab-previously-added" md-label="Previously Added" md-icon="history_edu">
                  <PreviouslyAdded></PreviouslyAdded>
                </md-tab>
                <md-tab id="tab-recently-played" md-label="Recently Played" md-icon="recent_actors">
                  <RecentlyPlayed></RecentlyPlayed>
                </md-tab>
                <md-tab id="tab-browse-categories" md-label="Browse Categories" md-icon="public">
                  <BrowseCategories></BrowseCategories>
                </md-tab>
              </md-tabs>
            </template>
          </nav-tabs-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopUserTracks from "./TopUserTracks";
import RecentlyPlayed from "./RecentlyPlayed";
import PreviouslyAdded from "./PreviouslyAdded";
import Recommendations from "./Recommendations";
import {NavTabsCard} from "../index";
import BrowseCategories from "./BrowseCategories";

export default {
  name: "TabsSection",
  created() {
    this.$on('hola', function(id){
      console.log('Event from parent component emitted', id)
    });
  },
  components: {
    BrowseCategories,
    TopUserTracks,
    RecentlyPlayed,
    PreviouslyAdded,
    Recommendations,
    NavTabsCard,
  },
  watch: {
    hola: function(n, o) {
      console.log(n)
      console.log(o)
    },
  },
  methods: {
    getPlaylistSongs2(playlistId) {
     console.log(playlistId)
    },
    hola(message) {
      console.log(message);
    },
  }
};
</script>

<style lang="css"></style>
