<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field class="md-form-group">
          <md-icon>audiotrack</md-icon>
          <md-input v-model="query" placeholder="Type your favorite song here"></md-input>
          <md-button class="md-icon-button md-raised md-accent md-just-icon md-rose" @click="searchSong">
            <md-icon>search</md-icon>
          </md-button>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <SearchSongResults :songs=query_results ></SearchSongResults>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../firebaseInit";
import SearchSongResults from "./SearchSongResults";

export default {
  name: "SearchSong",
  components: {
    SearchSongResults,
  },
  props: {
    playlistId: String,
  },
  data() {
    return {
      query: null,
      query_results: {
        tracks: {
          items: [],
        },
      },
    };
  },
  methods: {
    getPlaylistSongs() {
      this.query_results = {
        tracks: {
          items: []
        }
      }
      this.$parent.getPlaylistSongs(this.playlistId);
      this.query = ""
    },
    searchSong() {
      if (this.query === null || this.query === "") {
        this.$alert(
            "You must type the name of a song",
            "Empty search",
            "warning"
        );
      } else {
        this.show_loading = true;
        firebase
            .functions(process.env.VUE_APP_API_ENDPOINT)
            .httpsCallable("api/v1/search-song")({
              playlist_id: this.$route.params.playlist_id,
              query: this.query,
              user_id: this.$route.params.user_id,
            })
            .then((r) => {
              this.query_results = r.data;
            })
            .catch((e) => {
              this.$alert(e.response.data, "Error", "error");
            });
      }
    },
  }
}
</script>

<style scoped>

</style>
