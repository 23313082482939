<template>
<div>
  <div v-if="this.songs.length > 0">
    <div class="md-layout md-gutter">
      <div class="md-layout-item scrollmenu">
        <div style="min-height: 30px">
          <a href="#playlist-songs" v-for="(v, key) in this.songs" :index="key" :key="key"  @click="addSong(v.id, v.name, v.artists[0].name)">
            <b>{{ v.name }}</b>
            <span>
               by {{ v.artists[0].name }},
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {addSongToSpotify, recommendations} from "../../services/spotify";

export default {
  name: "Recommendations",
  mounted: function () {
    this.getRecommendations();
    setInterval(() => this.getRecommendations(), 60000);
  },

  data() {
    return {
      songs: []
    }
  },

  methods: {
    async getRecommendations() {
      const songs = await recommendations(this.$route.params.user_id, this.$route.params.playlist_id)
      if(songs !== undefined) {
        this.songs = songs
      }
    },
    async addSong(songId, songName, songArtist) {
      await addSongToSpotify(this.$route.params.user_id, this.$route.params.playlist_id, songId,  songName, songArtist)
      this.$root.$emit('refreshPlaylist')
    },
  }
}
</script>

<style scoped>
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
</style>
